*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.oculto{
    display: none;
}

header{
    background-color: #343A40;
    padding: 10px 20px 10px 100px;
}

header img{
    width: 120px;
    height: 50px;
}

#containerText{
    display: flex;
}

.boxText{
    width: 50%;
    padding: 30px 30px;
}

.boxText textarea{
    width: 100%;
    max-width: 100%;
    height: 300px;
    display: block;
    margin: 0 auto;
    padding: 10px;
    /*  */
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
}

#boxTextL h1{
    text-align: center;
    margin-bottom: 10px;
    font-size: 35px;
}

#boxTextL .bottom{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

#boxTextL .bottom p{
    margin: 0 10px;
}

#boxTextR h1{
    text-align: center;
    margin-bottom: 10px;
    font-size: 35px;
}

#boxTextR .bottom{
    display: flex;
    justify-content: right;
}

#boxTextR .bottom button{
    cursor: pointer;
    font-size: 18px;
    margin: 10px 0px 0 0;
    background-color: #85e9fd;
    padding: 5px 10px;
    border-radius: 10px;
    border: none;
}

/* containerButtons */
#containerButtons{
    display: flex;
    justify-content: center;
}

#containerButtons .center{
    max-width: 90%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#containerButtons .btn{
    padding: 5px 10px;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #0B5ED7;
    font-weight: 700;
    color: white;
    font-size: 20px;
    border: none;
    margin-bottom: 10px;
}

#containerButtons .btn.active{
    background-color: green;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}


/*  */
@media screen and (max-width: 800px){
    header{
        padding: 10px 20px 10px 20px;
    }
    #boxTextL{
        padding-top: 15px;
        padding-bottom: 0;
    }
    #boxTextR{
        padding-top: 20px;
    }
    #containerText{
        display: block;
    }
    .boxText{
        width: 100%;
    }
    .boxText textarea{
        height: 200px;
    }
    #containerButtons{
        display: flex;
        justify-content: center;
    }
    #containerButtons .center{
        max-width: 95%;
    }
    #containerButtons .btn{
        margin: 10px 10px;
        display: inline-block;
        font-size: 16px;
    }
}